import { Image } from '@graphcommerce/image'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, styled } from '@mui/material'
import { useRouter } from 'next/router'
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
// eslint-disable-next-line import/no-cycle
import missingImageIcon from '../../assets/images/missing-image-icon.svg'
// eslint-disable-next-line import/no-cycle
import { LayoutQuery } from '../Layout'
import { MegaMenuSubHeaderTab } from './MegaMenuSubHeaderTab'
import { SubCategoryTab } from './SubCategoryTab'
import { SubHeaderTabs } from './SubHeaderTabs'
import { megaMenuItems } from './mega-menu'

interface StyledTabsProps {
  children?: React.ReactNode
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
  variant?: 'standard' | 'scrollable' | 'fullWidth'
  scrollButtons?: boolean | 'auto'
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))(({ theme }) => ({
  color: '#000000',
  minHeight: 48,
  height: 48,
  fontWeight: 'bold',
  overflow: 'hidden',
  fontSize: 20,
  [theme.breakpoints.up('md')]: {
    minHeight: 48,
    height: 48,
  },

  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: 'none',
  },
}))

export interface StyledTabProps {
  label: string
  id: string
  value: string
  'aria-controls': string
  onHover?: () => void
  icon?: ReactElement<any>
  onClick?: () => void
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab
    disableRipple
    {...props}
    onMouseEnter={props.onHover}
    icon={props.icon}
    onClick={() => props.onClick && props?.onClick()}
  />
))(({ theme }) => ({
  minWidth: 0,
  paddingRight: 0,
  paddingLeft: 0,
  marginRight: '40px',
  textAlign: 'center',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: 14,
  color: '#000000',
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  '&.Mui-selected': {
    color: '#A95CFF',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#A95CFF',
  },
}))
export interface ImageItem {
  url: string
  alt: string
  label?: string
}

export interface MegaMenuItem {
  title: string
  url?: string
  image?: ImageItem
  children?: MegaMenuItem[]
}
export function MegaMenuHeaderTabs(props: Pick<LayoutQuery, 'menu'>) {
  const { menu } = props
  const [showMenu, setShowMenu] = useState(false)
  const [value, setValue] = useState<string>('')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  const router = useRouter()

  const handleClickParentCategory = async (url: string) => {
    await router.push(`/${url}`)
    setShowMenu(false)
  }

  useEffect(() => {
    if (showMenu) {
      setValue(value)
    } else {
      setValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu])

  const menuItems: Array<MegaMenuItem> = megaMenuItems

  return (
    <div
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
      className='bg-white flex w-full flex-col'
    >
      <TabContext value={value}>
        <div className='w-full px-5 2xl:px-0'>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
            sx={{
              width: '100%',
              display: 'flex',
              marginTop: 0,
              maxHeight: '90vh',
            }}
          >
            {menuItems?.map((category, index) => (
              <StyledTab
                label={category?.title ?? ''}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                onHover={() => setValue(index?.toString())}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                {...a11yProps(index)}
                onClick={
                  category?.url ? () => handleClickParentCategory(category?.url ?? '') : () => {}
                }
                value={index.toString()}
              />
            ))}
          </StyledTabs>
        </div>
        <div className='relative h-0 w-full '>
          {menuItems
            ?.filter((item) => item?.children && item?.children?.length > 0)
            .map((item, index) => (
              <TabPanel value={index.toString()} className='m-0 w-full p-0'>
                <div className='absolute left-0 top-0 z-10 h-full w-full border-t-[1px] border-t-20-grey bg-pure-white shadow-xl'>
                  <MegaMenuSubHeaderTab
                    menuItems={item?.children}
                    url={item?.url ?? ''}
                    setShowMenu={setShowMenu}
                    parent={item}
                  />
                </div>
              </TabPanel>
            ))}
        </div>
      </TabContext>
    </div>
  )
}
