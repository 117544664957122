// eslint-disable-next-line import/no-cycle
import { LayoutQuery } from '../Layout'
import { MegaMenuHeaderTabs } from './MegaMenuHeaderTabs'

export function MegaMenuHeader(props: Pick<LayoutQuery, 'menu'>) {
  const { menu } = props

  return (
    <div className='flex w-full max-w-maxContentWidth items-center justify-center'>
      <MegaMenuHeaderTabs menu={menu} />
    </div>
  )
}
