import Link from 'next/link'
import { Dispatch, SetStateAction } from 'react'
import missingFile from '../../assets/images/missing-file.png'
import { ResponsiveImageGridItem } from '../Image/ResponsiveImageGridItem'
// eslint-disable-next-line import/no-cycle
import { Icon } from '../Layout/Icon'
import { MegaMenuItem } from './MegaMenuHeaderTabs'

type MegaMenuSubHeaderTabProps = {
  menuItems?: MegaMenuItem[]
  className?: string
  url?: string
  setShowMenu: Dispatch<SetStateAction<boolean>>
  parent?: MegaMenuItem
}

export function MegaMenuSubHeaderTab(props: MegaMenuSubHeaderTabProps) {
  const { menuItems, className, url, setShowMenu, parent } = props

  return (
    <div
      className={`flex w-full items-start justify-center gap-x-10 divide-x-[1px] divide-20-grey border-[1px] border-b-2 border-t-0 border-20-grey bg-pure-white px-10 py-6 shadow-xl ${className}`}
    >
      <div className='grid items-start justify-end justify-items-start gap-4 gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
        {menuItems?.map((category) => (
          <div className='grid-cols-[1fr, minmax(200px)] grid items-start justify-start gap-1'>
            {category?.url && (
              <Link
                href={`/${category.url}` ?? ''}
                onClick={() => setShowMenu(false)}
                key={category.title}
                className='w-full  text-black hover:text-bright-templi-purple'
              >
                <h3>
                  <span className='md:Type-XL-Medium text-start'>{category.title}</span>
                </h3>
              </Link>
            )}
            {!category.url && (
              <h3>
                <span className='md:Type-XL-Medium mb-1 text-start text-70-grey'>
                  {category.title}
                </span>
              </h3>
            )}
            {category.children?.map((child) => (
              <div>
                {child?.url && (
                  <Link
                    href={`/${child.url}` ?? ''}
                    onClick={() => setShowMenu(false)}
                    key={child.title}
                    className='w-full  text-black hover:text-bright-templi-purple'
                  >
                    <h3>
                      <span className='md:Type-XL-Regular text-start md:my-3'>{child.title}</span>
                    </h3>
                  </Link>
                )}
                {!child.url && (
                  <h3>
                    <span className='md:Type-XL-Regular mb-1 text-start text-70-grey md:my-3'>
                      {child.title}
                    </span>
                  </h3>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className='flex items-start justify-start gap-5 px-10'>
        <div key={parent?.title} className='w-full text-black'>
          {parent?.image ? (
            <ResponsiveImageGridItem
              src={parent?.image?.url ?? ''}
              alt={parent?.image?.alt ?? ''}
              aspect='5/3'
              className=' mb-4 h-full max-h-[170px] min-h-[170px] w-full'
              hasHoverEffect={false}
              borderRadius='24px'
            />
          ) : (
            <ResponsiveImageGridItem
              src={missingFile?.src}
              alt={parent?.image?.alt ?? ''}
              aspect='5/3'
              className='rounded=[24px] mb-4 h-full max-h-[170px] min-h-[160px] w-full'
              hasHoverEffect={false}
              borderRadius='24px'
            />
          )}
          <h2>
            {parent?.url && (
              <Link
                href={parent?.url ?? ''}
                onClick={() => setShowMenu(false)}
                className='group flex items-center justify-start text-black hover:text-bright-templi-purple'
              >
                <span className='md:Type-XL-Medium Type-Large-Regular text-center'>
                  {parent?.image?.label ?? `Shop all ${parent?.title?.toLowerCase()}`}
                </span>
                <Icon
                  name='arrow_forward '
                  className='ml-1 text-[14px] font-bold duration-500 group-hover:translate-x-1'
                />
              </Link>
            )}
            {!parent?.url && (
              <div className='group flex items-center justify-start text-70-grey'>
                <span className='md:Type-XL-Medium Type-Large-Regular text-center'>
                  {parent?.image?.label ?? `Shop all ${parent?.title?.toLowerCase()}`}
                </span>
                <Icon name='arrow_forward ' className='ml-1 text-[14px] font-bold duration-500' />
              </div>
            )}
          </h2>
        </div>
      </div>
    </div>
  )
}
