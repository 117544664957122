import barRestaurant from '../../assets/images/bar_restaurant.jpeg'
import cafeBakery from '../../assets/images/cafe_bakery.jpeg'
import matches from '../../assets/images/matches.png'
import merch from '../../assets/images/merch.jpeg'
import shopAll from '../../assets/images/paper-bags.webp'
import printing from '../../assets/images/printing.png'
import weddingAndEvents from '../../assets/images/wedding&events.jpg'

export const megaMenuItems = [
  {
    title: 'Matches',
    url: '/products/matches',
    image: {
      url: matches?.src,
      alt: 'Matches',
    },
    children: [
      {
        title: 'USA Matchbooks',
        children: [
          {
            title: '20 Stem matchbook',
            url: '/p/20-stem-matchbook-200-moq-digitally-printed-atlas-match',
          },
          {
            title: '30 Stem matchbook',
            url: '/p/30l-30-stem-matchbook-digitally-printed-atlas-match',
          },
          {
            title: 'Foilboard matchbook',
            url: '/p/foil-board-matchbooks-atlas-match',
          },
          {
            title: 'Stock color matchbooks',
            url: '/p/20-stick-matchbook-atlas-match',
          },
        ],
      },
      {
        title: 'USA Matchbooks',
        children: [
          {
            title: 'Standard matchbox',
            url: '/p/matchbox-bx3-euro-domestic-2-25x1-5x0-4-atlas-match',
          },
          {
            title: 'Lipstick matchbox',
            url: '/p/lipstick-matchbox-digitally-printed-atlas-match',
          },
        ],
      },
      {
        title: 'Import Matchbooks',
        children: [
          {
            title: '10 Stem matchbook',
            url: '/p/10-stem-matchbook-2010-admatch',
          },
          {
            title: '12 Stem matchbook',
            url: '/p/12-stem-matchbook-2031-admatch',
          },
          {
            title: '15 Stem matchbook',
            url: '/p/15-stem-matchbook-2015-admatch',
          },
          {
            title: '20 Stem matchbook',
            url: '/p/20-stem-matchbook-2038-admatch',
          },
          {
            title: '30 Stem matchbook',
            url: '/p/30-stem-matchbook-2039-admatch',
          },
        ],
      },
      {
        title: 'Import Matchbooks',
        children: [
          {
            title: 'Tiffany matchbox',
            url: '/p/tiffany-matchbox-23-24-matchsticks-admatch',
          },
          {
            title: 'Thin Tiffany matchbox',
            url: '/p/thin-tiffany-matchbox-18-19-matchsticks-2-inch-admatch',
          },
          {
            title: 'Domino matchbox',
            url: '/p/domino-matchbox-16-17-matchsticks-admatch',
          },
          {
            title: 'Lipstick matchbox',
            url: '/p/lipstick-matchbox-21-22-matchsticks-admatch',
          },
          {
            title: 'Capri matchbox',
            url: '/p/capri-matchbox-28-29-matchsticks-admatch',
          },
          {
            title: 'Thin Riviera matchbox',
            url: '/p/thin-riviera-matchbox-22-23-matchsticks-admatch',
          },
          {
            title: 'Riviera matchbox',
            url: '/p/riviera-matchbox-33-34-matchsticks-admatch',
          },
          {
            title: 'Milady matchbox',
            url: '/p/milady-matchbox-10-11-matchsticks-2-inch-admatch',
          },
        ],
      },
    ],
  },
  {
    title: 'Events / Wedding',
    url: '/industries/wedding',
    image: {
      url: weddingAndEvents?.src,
      alt: 'Paper Bags',
    },
    children: [
      {
        title: 'Matches',
        url: '/industries/wedding/matches',
        children: [
          {
            title: '20 Stem matchbook',
            url: '/p/20-stem-matchbook-200-moq-digitally-printed-atlas-match',
          },
          {
            title: '30 Stem matchbook',
            url: '/p/30l-30-stem-matchbook-digitally-printed-atlas-match',
          },
          {
            title: 'Foilboard matchbook',
            url: '/p/foil-board-matchbooks-atlas-match',
          },
          {
            title: 'Standard matchbox',
            url: '/p/matchbox-bx3-euro-domestic-2-25x1-5x0-4-atlas-match',
          },
          {
            title: 'Lipstick matchbox',
            url: '/p/lipstick-matchbox-digitally-printed-atlas-match',
          },
        ],
      },
      {
        title: 'Napkins',
        url: '/industries/custom-party-napkins',
        children: [
          {
            title: 'Cocktail napkins',
            url: '/p/3-ply-white-beverage-napkin-digital-american-accents',
          },
          {
            title: 'Foil stamped napkins',
            url: '/p/colored-cocktail-napkins-hot-stamped',
          },
          {
            title: 'Dinner napkins',
            url: '/p/1-ply-almost-linen-white-dinner-napkin-digital-american-accents',
          },
          {
            title: 'Guest towels',
            url: '/p/1-ply-white-almost-linentm-guest-towel-digital-american-accents',
          },
        ],
      },
      {
        title: 'Coasters',
        url: '/industries/wedding/coasters',
        children: [
          {
            title: 'Bar coasters',
            url: '/p/quick-ship-pulpboard-coasters-80pt-american-accents',
          },
          {
            title: 'Cork coasters',
            url: '/p/cork-coasters-3-5in-round-1-color-printing-ventura-line',
          },
        ],
      },
      {
        title: 'Tape & Ribbon',
        children: [
          {
            title: 'Satin ribbon',
            url: '/p/custom-printed-satin-ribbon-ribbon-king',
          },
          {
            title: 'Grosgrain ribbon',
            url: '/p/custom-printed-grosgrain-ribbon-by-the-roll-ribbon-king',
          },
          {
            title: 'Full color ribbon',
            url: '/p/full-color-sublimated-satin-ribbon',
          },
        ],
      },
      {
        title: 'Menu Paper',
        children: [
          {
            title: 'Blank menu',
          },
          {
            title: 'Printed menus',
          },
        ],
      },
      {
        title: 'Bags',
      },
    ],
  },
  {
    title: 'Bar / Restaurant',
    image: {
      url: barRestaurant?.src,
      alt: 'Bar & Restaurant',
    },
    children: [
      {
        title: 'Menu Paper',
        children: [
          {
            title: 'Blank menu',
          },
          {
            title: 'Printed menus',
            url: '/p/classic-crest-menu-paper-printed-navitor',
          },
        ],
      },
      {
        title: 'Pens',
        url: '/products/merch-html/pens-pencils',
        children: [
          {
            title: 'Clic pen',
            url: '/p/clic-pen-koozie-group',
          },
          {
            title: 'Clic stic pen',
            url: '/p/custom-printed-bic-clic-stic-pen-koozie-group',
          },
          {
            title: 'Round stic pen',
            url: '/p/custom-printed-bic-round-stic-pen-koozie-group',
          },
        ],
      },
      {
        title: 'Coasters',
        url: '/products/coasters',
        children: [
          {
            title: 'Bar coasters',
            url: '/p/pulpboard-coasters-cmyk-offset-printed-katz-americas',
          },
          {
            title: 'Custom shaped',
            url: '/p/custom-shaped-pulpboard-coasters-katz-americas',
          },
          {
            title: 'Paperboard',
            url: '/p/paperboard-coasters',
          },
          {
            title: 'High volume',
            url: '/p/pulpboard-coasters-offset-printed-sonoco',
          },
          {
            title: 'Cork coasters',
            url: '/p/cork-coasters-3-5in-round-1-color-printing-ventura-line',
          },
          {
            title: 'Quick ship coasters',
            url: '/p/quick-ship-pulpboard-coasters-80pt-american-accents',
          },
        ],
      },
      {
        title: 'Paper Bags',
        url: '/products/paper-bags',
        children: [
          {
            title: 'Takeaway bags',
            url: '/products/paper-bags/takeaway-bags',
          },
          {
            title: 'Pastry bags',
            url: '/products/paper-bags/pastry-bags',
          },
        ],
      },
      {
        title: 'Napkins',
        url: '/products/napkins',
        children: [
          {
            title: 'Cocktail napkins',
            url: '/p/3-ply-white-beverage-napkin-digital-american-accents',
          },
          {
            title: 'Dinner napkins',
            url: '/p/1-ply-almost-linen-white-dinner-napkin-digital-american-accents',
          },
          {
            title: 'Guest towels',
            url: '/p/1-ply-white-almost-linentm-guest-towel-digital-american-accents',
          },
        ],
      },
      {
        title: 'Food Safe Paper',
        url: '/products/food-safe-paper',
        children: [
          {
            title: 'Tray liners',
            url: '/products/food-safe-paper/tray-liners',
          },
          {
            title: 'Butcher rolls',
            url: '/p/custom-butcher-paper-rolls',
          },
        ],
      },
    ],
  },
  {
    title: 'Cafe / Bakery',
    image: {
      url: cafeBakery?.src,
      alt: 'Cafe & Bakery',
    },
    children: [
      {
        title: 'Cups & Sleeves',
        url: '/products/cups',
        children: [
          {
            title: 'Coffee cups',
            url: '/products/cups/coffee-cups',
          },
          {
            title: 'Coffee sleeves',
            url: '/products/cups/coffee-sleeves',
          },
          {
            title: 'Cold drink cups',
            url: '/products/cups/cold-cups',
          },
        ],
      },
      {
        title: 'Paper Bags',
        url: '/products/paper-bags',
        children: [
          {
            title: 'Pastry bags',
            url: '/products/paper-bags/pastry-bags',
          },
          {
            title: 'SOS lunch bags',
            url: '/products/paper-bags/sos-lunch-bags',
          },
          {
            title: 'Takeaway bags',
            url: '/products/paper-bags/takeaway-bags',
          },
          {
            title: 'Baguette bags',
            url: '/p/french-bread-baguette-bag-printed-wisconsin-converting-inc',
          },
          {
            title: 'Bread loaf bags',
            url: '/p/wine-bread-bag-printed-5000-moq-wisconsin-converting-inc',
          },
        ],
      },
      {
        title: 'Napkins',
        url: '/products/napkins',
        children: [
          {
            title: '1 Ply napkins',
            url: '/p/1-ply-beverage-napkins-printed',
          },
          {
            title: 'Cocktail napkins',
            url: '/p/3-ply-white-beverage-napkin-digital-american-accents',
          },
          {
            title: 'Dinner napkins',
            url: '/p/1-ply-almost-linen-white-dinner-napkin-digital-american-accents',
          },
          {
            title: 'Guest towels',
            url: '/p/1-ply-white-almost-linentm-guest-towel-digital-american-accents',
          },
        ],
      },
      {
        title: 'Food Safe Paper',
        url: '/products/food-safe-paper',
        children: [
          {
            title: 'Tray liners',
            url: '/products/food-safe-paper/tray-liners',
          },
          {
            title: 'Butcher rolls',
            url: '/p/custom-butcher-paper-rolls',
          },
        ],
      },
      {
        title: 'Tape & Ribbon',
        url: '/products/tape-and-ribbon',
        children: [
          {
            title: 'Masking tape',
            url: '/p/custom-printed-masking-tape-continental-tape',
          },
          {
            title: 'Satin ribbon',
            url: '/p/custom-printed-satin-ribbon-ribbon-king',
          },
          {
            title: 'Grosgrain ribbon',
            url: '/p/custom-printed-grosgrain-ribbon-by-the-roll-ribbon-king',
          },
          {
            title: 'Full color ribbon',
            url: '/p/full-color-sublimated-satin-ribbon',
          },
        ],
      },
    ],
  },
  {
    title: 'Printing',
    url: '/products/printed-cards',
    image: {
      url: printing?.src,
      alt: 'Printing',
    },
    children: [
      {
        title: 'Business Cards',
        url: '/products/printed-cards/business-cards',
        children: [
          {
            title: 'Foiled cards',
            url: '/p/foil-business-cards-32pt-economy',
          },
          {
            title: 'Matte cards',
            url: '/p/matte-business-cards',
          },
          {
            title: 'Uncoated cards',
            url: '/p/full-color-flat-business-cards-navitor',
          },
          {
            title: 'Kraft cards',
            url: '/p/kraft-business-cards',
          },
          {
            title: 'Plastic cards',
            url: '/p/plastic-business-cards',
          },
          {
            title: 'Suede cards',
            url: '/p/suede-business-cards',
          },
          {
            title: 'Silk cards',
            url: '/p/silk-business-cards',
          },
        ],
      },
      {
        title: 'Postcards',
        url: '/products/printed-cards/postcards',
        children: [
          {
            title: 'Uncoated',
            url: '/p/uncoated-postcards',
          },
          {
            title: 'SOS lunch bags',
            url: '/products/paper-bags/sos-lunch-bags',
          },
          {
            title: 'Matte',
            url: '/p/matte-postcards',
          },
          {
            title: 'Suede',
            url: '/p/suede-postcards',
          },
          {
            title: 'Linen',
            url: '/p/linen-postcards',
          },
          {
            title: 'Foil',
            url: '/p/foil-postcards',
          },
        ],
      },
      {
        title: 'Stationary',
        url: '/products/custom-stationary',
        children: [
          {
            title: 'Notepads',
            url: '/p/notepads-3-5x8-5-notepads-25-sheets-per-pad-4-over-60lb-premium-opaque',
          },
          {
            title: 'Embosser',
            url: '/p/custom-book-embosser-discount-labels',
          },
          {
            title: 'Stamps',
            url: '/p/self-inking-stamps-discount-labels',
          },
          {
            title: 'Notecards',
            url: '/p/uncoated-postcards',
          },
          {
            title: 'Sticky notes',
            url: '/p/souvenirr-sticky-notetm-full-color-printed-koozie-group',
          },
        ],
      },
      {
        title: 'Stickers & Labels',
        url: '/products/labels',
        children: [
          {
            title: 'Custom ink labels',
            url: '/p/round-labels-custom-ink',
          },
          {
            title: 'Stock ink labels',
            url: '/p/quick-ship-circle-label',
          },
        ],
      },
    ],
  },
  {
    title: 'Merch',
    url: '/products/merch-html',
    image: {
      url: merch?.src,
      alt: 'Merch',
    },
    children: [
      {
        title: 'Tote Bags',
        url: '/products/merch-html/tote-bags',
        children: [
          {
            title: 'Muslin pouches',
            url: '/p/4oz-cotton-muslin-pouch-silkscreen-imprint',
          },
          {
            title: 'Canvas totes',
            url: '/p/full-color-all-over-print-made-to-order-tote-bag-koozie-group',
          },
        ],
      },
      {
        title: 'Bandanas',
        url: '/products/merch-html/bandanas',
        children: [
          {
            title: 'Made in USA',
            url: '/p/4800-bandana-printed-made-in-usa-caro-line-1',
          },
          {
            title: 'Import',
            url: '/p/4900-bandana-printed-imported-caro-line',
          },
        ],
      },
      {
        title: 'Drinkware',
        url: '/products/merch-html/tumbler',
        children: [
          {
            title: 'Mugs',
            url: '/products/merch-html/mugs',
          },
          {
            title: 'Tumblers',
            url: '/products/merch-html/tumbler',
          },
        ],
      },
      {
        title: 'Pens',
        url: '/products/merch-html/pens-pencils',
        children: [
          {
            title: 'Clic pen',
            url: '/p/clic-pen-koozie-group',
          },
          {
            title: 'Clic stic pen',
            url: '/p/custom-printed-bic-clic-stic-pen-koozie-group',
          },
          {
            title: 'Round stic pen',
            url: '/p/custom-printed-bic-round-stic-pen-koozie-group',
          },
        ],
      },
    ],
  },
  {
    title: 'Shop All',
    url: '/search/q/sort/new/dir/desc',
    image: {
      url: shopAll?.src,
      alt: 'Shop ALl',
    },
  },
]
