// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm, useFormAutoSubmit } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { Icon } from '../Layout/Icon'

export type SearchFormProps = {
  totalResults?: number
  search?: string
  urlHandle?: string
  autoFocus?: boolean
}

export function SearchForm(props: SearchFormProps) {
  const searchInputElement = useRef<HTMLInputElement>(null)

  useEffect(() => {
    searchInputElement.current?.focus()
  }, [])

  const { search = '', urlHandle = 'search' } = props
  const router = useRouter()

  const form = useForm({ mode: 'onSubmit', defaultValues: { search } })
  const { handleSubmit, register, reset, getValues } = form

  const submit = handleSubmit((formData) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace(`/${urlHandle}/${formData.search}`)
    reset(getValues())
  })
  useFormAutoSubmit({ form, submit })

  return (
    <div className='flex w-full items-center justify-center'>
      <form
        className='mx-5 flex w-full max-w-[700px] items-center justify-between rounded-[8px] border-[1px] border-50-grey bg-[#0000006] pl-[14px] pr-2 2xl:max-w-[800px]'
        onSubmit={submit}
      >
        <input
          type='text'
          className='h-[36px] max-h-[36px] w-full border-none bg-transparent text-xl text-tight-black outline-none focus:outline-0'
          placeholder={i18n._(/* i18n */ 'Search...')}
          {...register('search', { required: true, minLength: 2 })}
        />
        <Icon
          name='search'
          className='cursor-pointer text-3xl text-50-grey hover:text-material-ui-dark-blue'
          onClick={submit}
        />
      </form>
    </div>
  )
}
